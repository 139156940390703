'use client';

import { useRef, useState } from 'react';
import Button from '@/components/ui/Button';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const ImageUpload = ({
  id,
  accept = 'image/*',
  disabled,
  isClearable,
  onChange,
  onBlur,
  multiple = false,
  className,
  maxSize
}) => {
  const [value, setValue] = useState(null);
  const [errors, setErrors] = useState(null);
  const t = useTranslations();
  const ref = useRef(null);
  const handleChange = ({
    target
  }) => {
    setErrors(null);
    const files = Array.from(target.files);
    if (files && maxSize) {
      if (files.some(file => file.size > maxSize)) {
        setErrors([t('form.error.file_size', {
          size: maxSize / 1024 / 1024
        })]);
        target.value = '';
        return;
      }
    }
    if (!files.length || !files[0]) {
      setValue(null);
      if (onChange) {
        onChange(null);
      }
      return;
    }
    setValue(files);
    if (onChange) {
      onChange(target.files);
    }
  };
  const handleBlur = e => {
    if (onBlur) {
      onBlur(e);
    }
  };
  const resetField = () => {
    setValue(null);
    setErrors(null);
    ref.current.value = '';
    if (onChange) {
      onChange(null);
    }
  };
  const classes = cn({
    [className]: className
  });
  return <div className={classes} data-sentry-component="ImageUpload" data-sentry-source-file="ImageUpload.jsx">
      {value && !multiple && <div className="relative">
          <img src={URL.createObjectURL(value.at(0))} className="w-full mb-4 rounded" alt="Uploaded image" />

          {isClearable && <Button type="button" variant="red" icon="Trash" className="absolute top-3 right-3" onClick={() => resetField()} />}
        </div>}

      <input type="file" ref={ref} id={id} name={id} disabled={disabled} onChange={handleChange} onBlur={handleBlur} accept={accept} multiple={multiple} />

      {errors && Array.isArray(errors) && <div className="errors-container">
          {errors.map(error => <p key={error} className="error-message">
              {error}
            </p>)}
        </div>}
    </div>;
};
export default ImageUpload;