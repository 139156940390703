'use client';

import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { QUERIES } from '@/data/queries';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const Select = React.forwardRef(({
  id,
  fetchFn,
  defaultValue,
  disabled,
  isClearable,
  placeholder,
  onChange,
  onBlur,
  options,
  labelKey = 'label',
  valueKey = 'value',
  translateKey,
  maxLimit,
  multi,
  value,
  imageClasses,
  className,
  components
}, ref) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const t = useTranslations();
  const {
    data: optionData,
    isSuccess
  } = useQuery({
    queryKey: [`${QUERIES.AUTOCOMPLETE}.${id}`],
    queryFn: () => fetchFn()
  });
  const checkIfOptionDisabled = option => {
    const isMaxLimitReached = maxLimit && maxLimit > 0 && value && value.length >= maxLimit;
    const isOptionAlreadySelected = options && options.includes(option.value);
    return isMaxLimitReached && !isOptionAlreadySelected;
  };
  const handleChange = selectedOption => {
    if (onChange) {
      onChange(selectedOption);
    }
  };
  const handleBlur = e => {
    if (onBlur) {
      onBlur(e);
    }
  };
  useEffect(() => {
    if (options) {
      setSelectOptions(options);
    } else if (isSuccess) {
      const selectOptions = optionData?.length > 0 && optionData.map(option => {
        const label = translateKey ? t(`${translateKey}.${option[valueKey]}`) : option[labelKey];
        return {
          label,
          value: option[valueKey]
        };
      });
      setSelectOptions(selectOptions || []);
    }
  }, [options, isSuccess]);
  const classes = cn({
    [className]: className
  });
  return <div className={classes}>
        <ReactSelect ref={ref} isMulti={multi} isDisabled={disabled} isClearable={isClearable} onChange={handleChange} onBlur={handleBlur} options={selectOptions} defaultValue={defaultValue} placeholder={placeholder} isOptionDisabled={checkIfOptionDisabled} className="select" classNamePrefix="select" components={components} imageClasses={imageClasses} value={value} />
      </div>;
});
export default Select;