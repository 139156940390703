import Icon from '@/components/ui/Icon';
import { cn } from '@/utils';
export const InputCard = ({
  className,
  children,
  icon,
  iconType,
  image,
  label
}) => {
  const classes = cn({
    'input-card': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="InputCard" data-sentry-source-file="InputCard.jsx">
      <div className="input-card-content">
        {image && <div className="input-card-image">
            <img src={image} alt={label} />
          </div>}

        {icon && <Icon className="input-icon" name={icon} type={iconType} />}

        <div className="flex flex-col items-start w-full gap-4">
          {label && <span>{label}</span>}

          {children}
        </div>
      </div>
    </div>;
};
export default InputCard;