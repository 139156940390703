'use client';

import { useEffect, useState } from 'react';
import Button from '@/components/ui/Button';
import { MAX_ITEMS_REPEATER_FIELD } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const FieldRepeater = ({
  title,
  label,
  buttonLabel = 'Add new contact',
  isDisabled,
  iconBefore,
  iconAfter,
  errors,
  onBlur,
  onChange,
  onFocus,
  containerClassName,
  fieldsConfig,
  maxItems = MAX_ITEMS_REPEATER_FIELD
}) => {
  const initialValue = fieldsConfig.reduce((acc, {
    name
  }) => ({
    ...acc,
    [name]: ''
  }), {});
  const [items, setItems] = useState([]);
  const [error, setError] = useState(false);
  const t = useTranslations();
  useEffect(() => {
    onChange(items);
  }, [items]);
  const handleItemChange = (index, fieldName, value) => {
    setError(false);
    const newItems = [...items];
    newItems[index][fieldName] = value;
    setItems(newItems);
  };
  const addItem = () => {
    if (items.some(item => fieldsConfig.some(field => item[field.name] === '' && field.required))) {
      setError(t('form.field_repeater.error.empty_fields'));
      return;
    }
    if (items.length >= maxItems) {
      setError(t('form.field_repeater.error.max_items', {
        maxItems
      }));
      return;
    }
    setItems([...items, initialValue]);
  };
  const removeItem = index => {
    setError(false);
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };
  const classes = cn({
    [containerClassName]: containerClassName,
    'field-input-container': true,
    'field-icon-before': iconBefore,
    'field-icon-after': iconAfter,
    disabled: isDisabled
  });
  return <div className={classes} data-sentry-component="FieldRepeater" data-sentry-source-file="FieldRepeater.jsx">
      {title && <h5>{title}</h5>}
      {label && <label>{label}</label>}

      <div className="space-y-5">
        {items && items.length > 0 && items.map((item, index) => <div className="flex flex-col items-start justify-start gap-4" key={index}>
              <div className="grid w-full grid-cols-1 gap-3 md:grid-cols-7">
                {fieldsConfig.map(({
            name,
            placeholder,
            type,
            hint
          }) => <div className="flex flex-col md:col-span-3" key={`${index}-${name}`}>
                    <div className="w-full input-wrapper">
                      <input type={type} value={item[name]} name={name} onChange={e => handleItemChange(index, name, e.target.value)} onBlur={onBlur} onFocus={onFocus} placeholder={placeholder} disabled={isDisabled} />

                      {iconBefore && <span className="icon-left">{iconBefore}</span>}

                      {iconAfter && <span className="icon-right">{iconAfter}</span>}
                    </div>
                    {hint && <div className="hints-container">
                        <p className="m-0 mt-2 info-message">{hint}</p>
                      </div>}
                  </div>)}

                <Button key={`remove-${index}`} type="button" icon="Trash" variant="red-light" disabled={isDisabled} onClick={() => removeItem(index)} />
              </div>
            </div>)}

        <div className="flex items-center w-full gap-4">
          <Button type="button" icon="Plus" label={buttonLabel} variant="primary" disabled={isDisabled} onClick={addItem} data-sentry-element="Button" data-sentry-source-file="FieldRepeater.jsx" />
          {error && <p className="text-sm text-red-500">{error}</p>}
        </div>
      </div>

      {errors && Array.isArray(errors) && <div className="errors-container">
          {errors.map(error => <p key={error} className="error-message">
              {error}
            </p>)}
        </div>}
    </div>;
};
export default FieldRepeater;