'use client';

import React, { useEffect, useState } from 'react';
import Select from './Select';
export const Time = React.forwardRef(({
  defaultValue,
  disabled,
  onChange,
  separator = ':'
}, ref) => {
  const [minute, setMinute] = useState('');
  const [second, setSecond] = useState('');
  const handleMinuteChange = option => {
    setMinute(option.value);
  };
  const handleSecondChange = option => {
    setSecond(option.value);
  };
  useEffect(() => {
    if (defaultValue) {
      const [min, sec] = defaultValue.split(separator);
      setMinute(min.padStart(2, '0'));
      setSecond(sec.padStart(2, '0'));
    }
  }, [defaultValue]);
  useEffect(() => {
    if (minute !== '' && second !== '') {
      const fullTime = `${minute}${separator}${second}`;
      onChange(fullTime);
    }
  }, [minute, second]);
  const generateOptions = limit => {
    return Array.from({
      length: limit + 1
    }, (_, index) => {
      return {
        value: index.toString().padStart(2, '0'),
        label: index.toString().padStart(2, '0')
      };
    });
  };
  return <div ref={ref} className="flex items-center gap-2">
        <Select className="w-24" disabled={disabled} value={{
      value: minute,
      label: minute.padStart(2, '0')
    }} placeholder="00" options={generateOptions(10)} onChange={handleMinuteChange} />
        <span>{separator}</span>
        <Select className="w-24" disabled={disabled} value={{
      value: second,
      label: second.padStart(2, '0')
    }} placeholder="30" onChange={handleSecondChange} options={generateOptions(59)} />
      </div>;
});
export default Time;